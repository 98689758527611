<template>
	<div class="px-3 pt-3 pb-8 xl:px-0">
		<WwFaq
			id="faqs"
			:faq="faq"
			:heading="faqHeading"
			:columns="2"
			content-classes="grid grid-cols-1 gap-4"
		/>
	</div>
</template>

<script async>
import WwFaq from '@/components/UI/faq/WwFaq.vue'

export default {
	components: {
		WwFaq
	},
	props: {
		faq: {
			type: Array,
			required: true
		},
		faqHeading: {
			type: String,
			required: true
		}
	}
}
</script>
